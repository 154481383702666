import Cookies from 'js-cookie'
import {IS_DOMAIN, TOP_LEVEL_DOMAIN} from '@/config/config'

export default class Auth {

    static PLAT_TOKEN_KEY = "rx_plat_secret"
    static GOV_TOKEN_KEY = "rx_gov_secret"


    static getToken = function() {
        const token = Cookies.get(Auth.PLAT_TOKEN_KEY) || Cookies.get(Auth.GOV_TOKEN_KEY) || ''
        return token
    }

    static setToken = function(token) {
        IS_DOMAIN
            ? Cookies.set(this.PLAT_TOKEN_KEY, token, {domain: TOP_LEVEL_DOMAIN})
            : Cookies.set(this.PLAT_TOKEN_KEY, token);
    }

    static removeToken = function() {
        // window.localStorage.removeItem(Auth.TokenKey)
    }

    //判断用户是否登录
    static isLogin = () => {
        const token = Auth.getToken()
        return Boolean(token)
    }

}
