import {API_BASE_URL} from "@/config/config";
import axios from './axios'

// //登录
// export const  signInUser = params => axios.post('auth/signInUser',params)
// //退出登录
// export const  loginOut = params => axios.get('auth/loginOut',{params})
// //获取用户权限
// export const  getAdminUserPermissionList = params => axios.get('auth/getAdminUserPermissionList',{params})
// //免密登录
// export const autoSignInUser = params => axios.post("auth/autoSignInUser", params)

//大屏数据概览
export const  getDataOverview = params => axios.get('bi/data/getDataOverview',{params})
//排行榜
export const  rankingList = params => axios.get('bi/data/rankingList',{params})
//获取地区活动数据
export const  getActivityDataByArea = params => axios.get('bi/data/getActivityDataByArea',{params})
// 获取近期的岗位数据
export const  getRecentPostData = params => axios.get('bi/data/getRecentPostData',{params})
// 组织类型统计
export const  getOrganizationTypeStatistics = params => axios.get('bi/data/getOrganizationTypeStatistics',{params})
// 组织类型统计第二版
export const  getOrganizationTypeStatisticsV2 = params => axios.get('bi/data/getOrganizationTypeStatisticsV2',{params})
