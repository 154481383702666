import Auth from '@/model/auth'
import {getAdminUserPermissionList} from "@/api";

class User {

    state = {
        token: "",
        username: "",
        admin_name: "",
        login_time: "",
        org_area_id: "",
        unit_name: "",
        parent_id: "",   // 如果切换了区级, 则这个参数会保存这个区级id, 不是区级的话为空字符串

        region:'',
        regionIndex:'',

        district:[],
        DataType:0,
    }

    mutations = {
        // 保存用户信息
        setUserInfo: (state, payload) => {
            state.admin_name = payload.admin_name
        },

        // 保存用户token
        saveUserToken: (state, payload) => {
            state.token = payload;
        },

        // 保存切换后的省市区
        setRegion: (state, region) => {
            state.region = region;
        },

        // 区分省市区
        setRegionIndex: (state, regionIndex) => {
            state.regionIndex = regionIndex;
        },

        SET_DISTRICT:(state, district)=>{
            state.district = district;
        },

        //判断展示类型
        SET_DATATYPE:(state, DataType)=>{
            state.DataType = DataType;
        },
    }

    actions = {

        // 获取用户信息
        getUserInfo: async ({commit}) => {
            const {admin_info, level} = await getAdminUserPermissionList();
            if (level === "success") {
                commit("setUserInfo", admin_info);
                commit("saveUserToken", Auth.getToken())
            }
        },
    }

}

export default new User

