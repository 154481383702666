import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui'
import countTo from 'vue-count-to'
import { scrollBoard } from '@jiaminghi/data-view'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.scss'
import './directive/load-more'
import fullScreenContainer from "./components/dv-full-screen/dv-full-screen"

import * as api from './api/index'
import * as currency from './utils/utils'
import Auth from "./model/auth"

Vue.use(ElementUI)
Vue.use(scrollBoard)
Vue.component('countTo', countTo);
Vue.component('dvFullScreenContainer', fullScreenContainer)

Vue.prototype.$api = api
Vue.prototype.$currency = currency
Vue.prototype.$auth = Auth
Vue.prototype.$theme = '#0A5FEC'
Vue.prototype.$OSSURL = currency.ossFileURL
// Auth.setToken('Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvcnh6cC1zeXMtZGV2LmRpbmRvbmcuY25cL2FwaVwvYWRtaW5cL2F1dGhcL3NpZ25JblVzZXIiLCJpYXQiOjE2ODg2OTkzMjMsImV4cCI6MTY4ODg3MjEyMywibmJmIjoxNjg4Njk5MzIzLCJqdGkiOiJ1OUtFN3dHSVdiNmpHVXJKIiwic3ViIjoxLCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIn0.BBNGGG9zQzUPzKw9buv26GYqNQQwqrVboWDV0J61KwU')
Vue.config.productionTip = false
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
