import Vue from 'vue';
import axios from 'axios';
import router from '@/router/index';

import {API_BASE_URL, GET_WEATHER_URL, BAI_DU_MAP_AK} from '@/config/config';
import Auth from '@/model/auth';
import {notify} from '@/utils/utils';

const config = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    },
    baseURL: API_BASE_URL,
    timeout: 0,
    responseType: 'json',
    maxContentLength: -1,
    withCredentials: true,
    crossDomain: true,
    validateStatus(status) {
        return status >= 200 && status < 510;
    },
};

const service = axios.create(config);

// 获取天气数据
export const getWeatherInfo = (params) => {
    return axios.get(GET_WEATHER_URL, {
        params: {
            version: 'v6',
            appid: 95122639,
            appsecret: 'MQaOQl5r',
            ...params,
        },
    });
};

service.interceptors.request.use((res) => {
    // console.log(res.headers)
    res.headers['Authorization'] = Auth.getToken();
    return res;
});

service.interceptors.response.use((response) => {

    if (response.status == 500) {
        Vue.prototype.$notify({
            title: '服务器异常',
            dangerouslyUseHTMLString: true,
            message: '<strong class="my-notify">请检查API是否异常</strong>', //请检查API是否异常
        });
        throw new Error('服务器500异常');
    } else if (response.status == 429) {
        Vue.prototype.$notify({
            title: '429',
            dangerouslyUseHTMLString: true,
            message: '<strong class="my-notify">Too Many Request</strong>', //请检查API是否异常
        });
        throw new Error('429');
    }

    // 重定向到登录页面
    if (
        response &&
        response.data &&
        response.data.hasOwnProperty('data') &&
        response.data.data === 'no_login'
    ) {
        Auth.removeToken();
        // 退出登录后在登录页不需要提示"请先登录"
        // console.log(response.config)
        // response.config.url !== 'auth/loginOut' && notify('error', '请先登录 !');
        // router.replace({path: '/login'});
        return response.data;
    }

    if (response && response.data && response.data.level === 'error') {
        notify('error', response.data.message);
    }
    return response.data;
}, error => {
    if (!error.response) {
        Vue.prototype.$notify({
            title: 'Network Error',
            dangerouslyUseHTMLString: true,
            message: '<strong class="my-notify">请检查 API 是否异常</strong>',
        });
    }

    // 判断请求超时
    if (
        error.code === 'ECONNABORTED' &&
        error.message.indexOf('timeout') !== -1
    ) {
        Vue.prototype.$message({
            type: 'warning',
            message: '请求超时',
        });
    }
    return Promise.reject(error);

});

export default service;
