const getters = {
    name: state => state.user.admin_name,
    region: state => state.user.region,
    regionIndex: state => state.user.regionIndex,
    district: state => state.user.district,
    DataType: state => state.user.DataType,
    // org_area_id: state => state.user.org_area_id,
    // parent_id: state => state.user.parent_id,
}

export default getters;
