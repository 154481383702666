import Vue from 'vue'
import Vuex from 'vuex'

import getters from "./getters"
import user from './modules/user'
import {formatTime} from "@/utils/utils";


Vue.use(Vuex)

export default new Vuex.Store({

  modules: {
    user,               // 用户信息相关
  },

  getters,

  state: {
    current_date: "",
    current_time: "",
  },
  mutations: {

    // 获取当前的时间
    GET_CURRENT_TIME: state => {
      const [year, month, day, hour, minute, second] = formatTime(new Date, 3);
      state.current_date = `${year}/${month}/${day}`;
      state.current_time = `${hour}:${minute}:${second}`;
    },


  },
  actions: {},

})
