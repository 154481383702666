import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import Auth from '@/model/auth'
import {notify} from "@/utils/utils";
import store from "@/store";
import {autoSignInUser} from "@/api";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

const whiteList = ['/login']
// router.beforeEach(async (to, from, next) => {
//
//   if (to.query.source === 'login') {
//     const {data, level} = await autoSignInUser({id: to.query.id})
//     if (level === 'success') {
//       Auth.setToken(data)
//       store.commit('saveUserToken', data)
//       await router.replace('/')
//     }
//   }
//
//   // 判断有无token
//   if (Auth.isLogin()) {
//     if (to.path === '/login') {
//       next({path: '/'});
//     } else {
//       const hasGetUserInfo = store.getters.name;
//       if (hasGetUserInfo) {
//         next();
//       } else {
//         try {
//           await store.dispatch('getUserInfo');
//           next();
//         } catch (e) {
//           notify("error", "请先登录 !");
//           // next(`/login`);
//         }
//       }
//     }
//   } else {
//
//     if (whiteList.indexOf(to.path) !== -1) {
//       next()
//     } else {
//       next(`/login`)
//     }
//   }
// })

export default router
